<template>
  <section class="g3">
    <ul>
      <li><span class="title"> TE ATENDEMOS </span><span class="text"> <strong>EM 15 </strong> MINUTOS </span></li>
      <li class="skew">
        <h2 class=" section_header "> <a href="tel:19988281364"><span class="highlight thin "><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
</svg>&nbsp;&nbsp;<strong>(19)&nbsp;98828-1364</strong></span></a> </h2>
      </li>
      <li>
        <div class="fw-column-inner padding_20">
          <div class="fw-divider-space " style="margin-top:5px;"></div>
          <div class="media small-teaser shortcode-icon nitro-offscreen">
            <div class="media-left">
              <div class="icon-wrap"> <i class="toyicon-phone black fontsize_18"></i> </div>
            </div>
            <div class="media-body"> <span class="title"> <strong>PROFISSIONAIS DISPONÍVEIS</strong> </span> <span
                class="text"> 24 HORAS POR DIA </span> </div>
          </div> <span class="theme-icon bg_icon icon_left"> <i class="toyicon-phone default_icon"></i> </span>
        </div>
      </li>
    </ul>
  </section>
  <section class="banner">
    <div>
      <h2>Seu Socorro em Campinas 24 Horas por Dia, 7 Dias por Semana !</h2>
      <h4>Ligou, chamou, chegou</h4>
      <WhatsApp/>
    </div>
  </section>
</template>
<style lang="scss" scoped>
@import '../scss/mixin.scss';

* {
  overflow: hidden;
}
section {
  @include tamanho(100vw!important, auto);
  .banner{
    *{
      @include tamanho(100vw!important, auto);
      text-align: center!important;
    }
  }
}
.g3 {
  margin:2px 0 !important;
  * {
  color: #fff !important
}
  ul {
    position: relative;
    left: 0;
    padding: 0 !important;
    margin: 0 !important;
    display: grid;

    grid-template-columns: repeat(3, calc(100vw/3));

    @media only screen and (max-width: 600px) {
      max-width: 100% !important;
      grid-template-columns: repeat(1, 100vw);
    }

    background: #b60000;

    * {
      color: #fff;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 600px) {
        min-height: 100px !important;
      }
    }

    .skew {
      background: #111111;
      h2 span a strong {
        color: #b60000 !important;
      }
      padding: 10px;
      -webkit-transform: skew(-30deg);
      -ms-transform: skew(-30deg);
      transform: skew(-30deg);

      /* SKEW inverso para o texto não inclinar */
      @media only screen and (max-width: 600px) {
        @include tamanho(100vw!important, auto);
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        /* SKEW inverso para o texto não inclinar */
      }
    }
  }
}

.g4 {
  div {
    padding: 1rem 0 !important;

    * {
      margin: 2rem 0 !important;
    }
  }

  ul {

    display: grid;
    grid-template-columns: repeat(4, calc(100%/4));

    @media only screen and (max-width: 600px) {
      max-width: 100% !important;
      grid-template-columns: repeat(1, 100%);
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem 0 !important;

      * {
        margin: 2rem 0 !important;
      }
    }
  }
}

span img {
  vertical-align: middle;
}</style>
<script async>
import WhatsApp from '@/components/Whatsapp.vue'
export default {
  name: 'start-cps',
  components: {
    WhatsApp
  }
}
</script>
