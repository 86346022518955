<template>
    <header>
        <div class="header-top">
            <a href="tel:19988281364" target="_blank" rel="noopener noreferrer">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#b60000" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
</svg>
                    <strong>&nbsp;&nbsp;(19) 9 8828-1364</strong>
                </span>
            </a>
            <a href="https://api.whatsapp.com/send?phone=5519988281364&amp;text=Olá%20Marcelo%20Guincho%20Campinas,%20gostaria%20de%20solicitar%20um%20orçamento."
                target="_blank" rel="noopener noreferrer">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#b60000" class="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
</svg>
                    <strong>&nbsp;&nbsp;(19) 9 8828-1364</strong>&nbsp;&nbsp;
                </span>
            </a>
        </div>
        <nav class="navbar">
            <div style="display: none;">
                <div>&nbsp;&nbsp;&nbsp;<img src="" width="120" alt="logo"/></div>
                <div><a id="toggle-menu" class="toggle-menu" v-on:click="menu">
                        <i></i>
                        <i></i>
                        <i></i>
                    </a></div>
            </div>
            <div style="display: none;"> <ul id="subnav" class="">
                    <li class="">
                        <router-link class="nav__link" v-on:click="menu" to="/">Home</router-link>
                    </li>
                    <li class="">
                        <RouterLink v-on:click="menu" to="/a-empresa/" class="m">A
                            Empresa</RouterLink>
                    </li>
                    <li class="">
                        <RouterLink to="/servicos/" v-on:click="menu" class="m">Serviços</RouterLink>
                    </li>
                    <li class="">
                        <RouterLink to="/galeria-de-fotos/" v-on:click="menu" aria-current="page" class="m m-active">Galeria
                        </RouterLink>
                    </li>
                    <li class="">
                        <RouterLink to="/fale-conosco/" v-on:click="menu" class="m">Fale Conosco</RouterLink>
                    </li>
                </ul></div>
        </nav>
    </header>
</template>
<script>
export default {
  name: 'header-cps',
  data () {
    return {
      escreenWidth: screen.width,
      escreenHeight: screen.height
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.escreenWidth = window.innerWidth
      this.escreenHeight = window.innerHeight
    },
    menu () {
      if (this.escreenWidth <= 600) {
        if (document.getElementById('subnav').style.display === 'none') {
          document.getElementById('subnav').style.display = 'block'
        } else {
          document.getElementById('subnav').style.display = 'none'
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#subnav {
    height: 40px;
    li {
    display: flex;
    align-items: center;
    }
    a:hover {
      border-bottom: 2px solid #b60000!important;
      color:#b60000!important
    }
    display: flex;
    @media only screen and (max-width: 600px) {
        *{
        min-width: 100vw!important;
        text-align: center;
        }
        display: none;
    }
}
/* CSS */
.toggle-menu {
    display: none;
    @media only screen and (max-width: 600px) {
        display: inline-block;
    }
    width: 50px;
    height: 50px;
    position: relative;
    top: 0;
    z-index: 1000;
    background: none;
    border: none;
}

.toggle-menu i {
    position: absolute;
    display: block;
    height: 2px;
    background: #F13A3C;
    width: 30px;
    left: 0px;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.toggle-menu i:nth-child(1) {
    top: 16px;
}

.toggle-menu i:nth-child(2) {
    top: 24px;
}

.toggle-menu i:nth-child(3) {
    top: 32px;
}

.toggle-menu.active i:nth-child(1) {
    top: 25px;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.toggle-menu.active i:nth-child(2) {
    background: transparent;
}

.toggle-menu.active i:nth-child(3) {
    top: 25px;
    -webkit-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
}

/*  */
header {
    display: grid;
    grid-template-rows: repeat(2, auto);
    margin-bottom: .5rem !important;
    *{
        overflow: hidden;
    }
}

* {
    div {
        height: auto !important;
        margin: 0 !important;

        &:nth-child(1) {
            height: 64px !important;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        &:nth-child(2){
            display: flex;
            align-items: center;
        }

        margin-bottom:.1rem !important;
    }
}
a {
    margin: 1rem !important;
}
/* .logo img {
    max-width: 150px !important;
    float: left;
    margin-left: 1rem !important;
} */

.navbar {
    display: flex!important;
    align-items: center!important;
    justify-content: space-between;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    min-width: 90vw !important;
    @media only screen and (max-width: 600px) {
        min-width: 100vw !important;
        justify-content:stretch;
        flex-direction: column;
        padding: 0;
    }
}

.nav-menu {
    @media only screen and (max-width: 600px) {
        border-radius: 0;
        background: #ccc;
        color: #b60000 !important;
    }
    display: grid;
    transition: all .25s ease-in;
    max-width: 62vw !important;
    grid-template-columns: repeat(5, calc(55vw/5));
    @media only screen and (max-width: 600px) {
        max-width: 100vw !important;
        margin: 0!important;
        grid-template-columns: repeat(1, 100%);
    }
    column-gap: 5px;
    row-gap: 2.5px;
    text-align: center !important;

    @media screen and (max-width: 768px) {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translatey(-100%);

        .menu-active & {
            transform: translatey(0%);
            opacity: 1;
        }
    }

    .menu-item a {
        display: block;
        line-height: 30px;
        margin: 0px 10px;
        text-transform: uppercase;

        &:hover {
            text-decoration: underline;
        }

        @media screen and (max-width: 768px) {
            font-size: 20px;
            margin: 8px;
        }
    }
}

.sub-nav {
    display: none;
    position: absolute;
    padding: 5px 5px;
    list-style: none;
    width: 230px;

    @media screen and (max-width: 768px) {
        position: relative;
        width: 100%;
        display: none;
        background-color: rgba(0, 0, 0, 0.20);
        box-sizing: border-box;
    }
}

.nav__link {
    &:hover+.sub-nav {
        display: block;
    }
}

.sub-nav {
    &:hover {
        display: block;
    }
}
</style>
