<template>
  <h2>&nbsp;&nbsp;&nbsp;Pesquisas relacionadas</h2>
  <ul>
    <li>Guincho campinas</li>
    <li>Guincho campinas preço</li>
    <li>Guincho Mais Proximo</li>
    <li>Guincho 24 Horas Campinas</li>
    <li>Guincho Centro Campinas</li>
    <li>Guincho Campo Grande Campinas</li>
    <li>Guincho Campinas Ouro Verde</li>
  <li>guinchos em campinas</li>
  <li>guincho campinas sp</li>
<li>guincho 24 horas em campinas</li>
<li>guinchos campinas</li>
<li>guincho campinas preço</li>
<li>auto socorro campinas</li>
<li>guincho 24 horas campinas</li>
<li>auto socorro 24 horas campinas</li>
<li>serviço de guincho em campinas sp</li>
<li>guincho plataforma em campinas</li>
<li>guincho 24 horas campinas sp</li>
<li>guincho campinas 24h</li>
<li>telefone de guincho em campinas</li>
<li>guincho em campinas</li>
<li>guincho 24h campinas</li>
<li>guincho campinas</li>
<li>guincho e reboque em campinas sp</li>
<li>guincho em campinas sp</li>
<li>guincho em campinas 24 horas</li>
<li>guincho regiao ouro verde campinas</li>
<li>serviço de guincho campinas</li>
<li>guincho campo grande campinas</li>
<li>guinchos campinas sp</li>
<li>guincho campinas ouro verde</li>
<li>guincho moto campinas</li>
<li>guincho em campinas 24 horas</li>
<li>guincho 24 horas em campinas</li>
<li>guincho 24 horas campinas sp</li>
<li>guincho regiao ouro verde campinas</li>
<li>guincho campinas centro</li>
<li>reboque campinas</li>
<li>telefone de guincho em campinas</li>
<li>guinchos em campinas sp</li>
<li>guinchos campinas</li>
<li>guincho plataforma em campinas</li>
<li>guincho 24 horas campinas</li>
<li>guincho flamboyant campinas</li>
<li>guincho campo grande campinas</li>
<li>guincho campinas taquaral</li>
<li>guincho em campinas</li>
<li>guinchos em campinas</li>
<li>guincho em campinas sp</li>
<li>guincho campinas barão geraldo</li>
<li>guincho campinas sp</li>
<li>guincho plataforma campinas</li>
<li>guincho campinas ouro verde</li>
<li>guincho em campinas telefone</li>
<li>guincho campinas campinas sp</li>
<li>guincho campinas campo grande</li>
<li>guincho campinas 24 horas</li>
<li>serviço de guincho campinas</li>
<li>guincho campinas</li>
<li>guincho 24h campinas</li>
<li>guincho 24 horas em campinas sp</li>
<li>serviço de guincho 24 horas campinas</li>
<li>guincho regiao campo grande campinas</li>
<li>serviço de guincho em campinas sp</li>
<li>guincho campinas preço</li>
<li>serviço de guincho em campinas</li>
<li>guinchos 24 horas em campinas</li>
<li>guincho campinas 24h</li>
<li>serviço guincho campinas</li>
<li>auto socorro campinas</li>
<li>guincho campinas jardim do trevo</li>
<li>guincho e reboque em campinas sp</li>
<li>caminhao guincho plataforma campinas</li>
<li>guincho e reboque em campinas</li>
<li>reboque 24 horas campinas</li>
<li>guincho barato campinas</li>
<li>auto socorro 24 horas campinas</li>
<li>guinchos campinas sp</li>
  </ul>
</template>
<script async>
export default {
  name: 'search-cps'
}
</script>
<style lang="scss" scoped>
*{
  text-align: left;
}
ul{
  margin: 0 20px!important;
  max-width: 100vw!important;
  display: grid;
  grid-template-columns: repeat(4, calc(100vw/4));

    @media only screen and (max-width: 600px) {
      max-width: 100% !important;
      grid-template-columns: repeat(1, 100vw);
    }
    li{
      margin-top: 2px;
      &:first-letter { text-transform: capitalize; }
    }
}
</style>
