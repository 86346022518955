<template>
  <div class="home">
    <Banner/>
    <Start/>
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/Banner.vue'
import Start from '@/components/Start.vue'
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    Banner,
    Start,
    HelloWorld
  }
}
</script>
