<template async>
  <div>
      <div class="img img-banner " v-for="(slide) in slides" v-bind:key="slide.sublineSecondLine"
        v-bind:style="{ 'display': '' + slide.display + '', 'background-image': 'url(' + slide.Img + ')' }">
        <div class="example">
          <div class="n2-style">
            <h1 class="font-hover">{{slide.sublineSecondLine }}</h1>
            </div>
        </div>
      </div>
    </div>
</template>
<style scoped lang="scss" async>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,700&display=swap');
section{
  margin-bottom: 3rem!important;
}
*{
  overflow: hidden;
}
.example {
  background: none;
  display: -moz-box; /* Mozilla */
  display: -webkit-box; /* WebKit */
  height: 400px;
  /* Children should be oriented vertically */
  -moz-box-orient: vertical; /* Mozilla */
  -webkit-box-orient: vertical; /* WebKit */
  /* Align children to the horizontal center of this box */
  -moz-box-align: center; /* Mozilla */
  -webkit-box-align: center; /* WebKit */
  /* Pack children to the bottom of this box */
  -moz-box-pack: end; /* Mozilla */
  -webkit-box-pack: end; /* WebKit */
}
.img-banner  {
  max-width: 100%!important;
  height:400px!important;
  background-repeat: no-repeat!important;
  background-size: cover;
  @media only screen and (max-width: 600px) {
    background-position: 50% 25%;
  }
}
.div-banner {
  position: absolute;
  bottom: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slideteste {
  transition: 'transform 0.4s ease';
  transform: 'translateX(0%)';
  z-index: '1'
}
.n2-style {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000aa;
  border-radius: 0px;
  max-width: 100%!important;
  min-width: 100%!important;
  max-height: 400px!important;
  min-height: 400px!important;
}
.font-hover {
  font-family: 'Montserrat', sans-serif;
  color: #ffffff!important;
  font-weight: 700;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: normal;
  word-spacing: normal;
  z-index: 99999!important;
}
</style>
<script async>
export default {
  name: 'banner-cps',
  data () {
    return {
      currentSlide: 0,
      isPreviousSlide: false,
      isFirstLoad: true,
      slides: [
        {
          sublineSecondLine: 'SERVIÇO DE GUINCHO 24 HORAS',
          Img: require('@/assets/grua.webp'),
          display: 'none'
        },
        {
          sublineSecondLine: 'SOLICITE AGORA MESMO',
          Img: require('@/assets/grua.webp'),
          display: 'block'
        }
      ]
    }
  },
  mounted () {
    this.nextSlide()
  },
  methods: {
    nextSlide () {
      for (const x in this.slides) {
        if (this.slides[x].display === 'none') {
          this.slides[x].display = 'block'
        } else {
          this.slides[x].display = 'none'
        }
      }
      setTimeout(this.nextSlide, 7000)
    }
  }
}
</script>
