<template async>
    <footer>
        <a
            href="https://api.whatsapp.com/send?phone=5519988281364&amp;text=Olá%20Marcelo%20Guincho%20Campinas,%20gostaria%20de%20solicitar%20um%20orçamento."><button
                class="btn-whats"><img async width="32" height="32" alt="Whatsapp" src="@/assets/whatsapp-32.svg"></button></a>
                <ul>
                    <li>
                        <p>
                            A <strong> Marcelo Guincho Campinas</strong> é especializada em reboque, combinando experiência e inovação para oferecer serviços de qualidade excepcional. Estamos comprometidos em superar as expectativas, fornecendo soluções rápidas e confiáveis para emergências ou problemas com veículos. Conte conosco para superar desafios na estrada com expertise e dedicação</p>
                    </li>
                    <li>
                        <p>WhatsApp: 19&nbsp;<strong>9&nbsp;8828-1364</strong></p>
                    </li>
                    <li>
                        <a href="mailto:contato@marceloguinchocampinas.com.br">contato@marceloguinchocampinas.com.br</a>
                    </li>
                </ul>
                <div><img src="@/assets/cartoes.webp" width="250" alt=""></div>
    <div><img src="@/assets/ssl.webp" width="250" alt=""></div>
        <p>Copyright @ {{ year }} - Todos os direitos reservados</p>
    </footer>
</template>
<script async>
export default {
  name: 'footer-cps',
  data: function () {
    return {
      year: ''
    }
  },
  created () {
    this.year = new Date().getFullYear()
  }
}
</script>
<style async scoped lang="scss">
$c_20d366: #28a745;
$n: none;
footer {
    padding: 1rem !important;
    margin-top: 5rem!important;
    background: #313133;
    * {
        color: #b9b9b9 !important;
        text-align: left !important;
        overflow: hidden !important;
        margin-bottom: .5rem !important
    }
    .grid {
  div{
    border-radius: none;
    background: none;
  }
}
    border-top: solid .5rem #313133;
    padding-top: 2rem !important;
    strong {
        color: #e2e2e2 !important
    }
}
.btn-whats {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 70px;
    height: 70px;
    bottom: 40px;
    left: 93% !important;
    border: 0;
    @media only screen and (max-width: 600px) {
        left: 75% !important
    }
    z-index: 6;
    background: $c_20d366;
    border-radius: 1000px;
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: $n;
    color:$n;
}
/* .btn::before {
    content: '';
    border-radius: 100px;
    min-width: calc(70px + 6px);
    min-height: calc(70px + 6px);
    border: 3px solid $c_20d366;
    position: $abs;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease-in-out 0s;
  } */
.btn:hover,
.btn:focus {
    color: #313133;
    transform: translateY(-6px);
}
.btn:hover::after,
.btn:focus::after {
    animation: none;
    display: none;
}
@keyframes ring {
    0% {
        width: 30px;
        height: 30px;
        opacity: 1
    }
    100% {
        width: 150px;
        height: 150px;
        opacity: 0
    }
}
</style>
