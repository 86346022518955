<template async>
  <section>
    <ul class="grid">
      <li><router-link to="/">
          <div class="image-container">
            <img async width="310" src="@/assets/carro.webp" layout="fixed" alt="mudancas-residenciais">
          </div>
          <h4>Guincho para Automoveis</h4>
          <p>
            Serviço de Guincho Especializado para Automóveis, com a comodidade de pagamento via Cartões de Crédito. Atendemos tanto clientes individuais quanto empresas, realizando remoções eficientes com guincho plataforma, proporcionando agilidade e tarifas justas. Cobrimos toda a cidade de Campinas e Região, São Paulo Capital, litoral e interior. Conte conosco para um atendimento rápido, seguro e com preços competitivos.
          </p>
          <p><strong>Saiba Mais</strong></p>
      </router-link></li>
      <li> <router-link to="/">
        <div class="image-container">
          <img async width="310" src="@/assets/moto.webp" layout="fixed" alt="mudancas-residenciais">
        </div>
        <h4>Guincho para motos</h4>
        <p>
          Serviço de Guincho para Motos com Pagamento via Cartões de Crédito.Realizando remoções ágeis com guincho plataforma, garantindo praticidade e preços justos. Estamos disponíveis em toda a cidade de Campinas e Região, São Paulo Capital, litoral e interior. Conte conosco para um atendimento rápido e confiável.
        </p>
        <p><strong>Saiba Mais</strong></p>
      </router-link></li>
      <li><router-link to="/">
        <div class="image-container">
          <img async width="310" src="@/assets/empinhadeira.webp" layout="fixed" alt="fretes-empresariais">
        </div>
        <h4 class="title">Guincho para Empilhadeira</h4>
        <p>
          Guincho Especializado para Empilhadeiras. Aceitamos Pagamento com Cartões de Crédito e Oferecemos Atendimento Empresarial. Realizamos Remoções com Guincho Plataforma de Maneira Rápida e com Preços Justos. Estamos à disposição em toda a cidade de Campinas e Região, São Paulo Capital, litoral e interior. Conte conosco para um serviço eficaz e confiável.
        </p>
        <p><strong>Saiba Mais</strong></p>
      </router-link></li>
      <li><router-link to="/">
        <div class="image-container">
          <img async width="310" src="@/assets/embarcacoes.webp" layout="fixed" alt="">
        </div>
        <h4 class="title">Guincho para Embarcações</h4>
        <p>
          Serviço de Guincho para Embarcações com Pagamento via Cartões de Crédito. Atendemos Empresas com Remoções Rápidas e Preços Justos através de Plataforma de Guincho. Estamos disponíveis em toda a cidade de Campinas e região, São Paulo Capital, litoral e interior.
        </p>
        <p><strong>Saiba Mais</strong></p>
      </router-link></li>
    </ul>
  </section>
  <Whatsapp/>
</template>
<script async>
import Whatsapp from '@/components/Whatsapp.vue'
export default {
  name: 'servico-cps',
  components: {
    Whatsapp
  }
}
</script>
<style async lang="scss" scoped>
*{
  text-align: left;
}
li{
  background: #fff;
  border-radius:15px;
}
.image-container {
  border-radius:15px 15px 0 0;
    min-width: 100%!important;
    height: auto;
    max-width: 200px!important
}
.image-container img {
    min-width: 100%;
    height: auto;
}
.grid{
  div{
   background: #fff;
  }
}
p {
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Define o número de linhas a serem exibidas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 5.4em; /* Altura máxima equivalente a 3 linhas de texto */
}
</style>
