<template>
  <Galeria/>
  <Search/>
</template>

<script>
import Galeria from '@/components/Servicos.vue'
import Search from '@/components/Search.vue'
export default {
  name: 'HelloWorld',
  components: {
    Galeria,
    Search
  }
}
</script>
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
